import React from "react"

import Layout from "../components/layout"
import categories from "../content/essayCategories.json"

import Breadcrumbs from "../components/Breadcrumbs"
import TheCategoriesList from "../components/TheCategoriesList";

const EssayCategoriesList = () => {
    const steps = [
        {
            name: 'Sample essays categories',
            path: '/sample-essays'
        }
    ]
    return (
        <>
            <Layout>
                <div className="essay-intro-categories">
                    <div className="wrapper">
                        <Breadcrumbs steps={steps}/>
                    </div>
                </div>
                <div className="wrapper">
                    <TheCategoriesList
                        categories={categories}
                        url="sample-essays/category"
                        title="Sample essay categories"
                    />
                </div>
            </Layout>
        </>
    )
}

export default EssayCategoriesList
