import React from "react"

const TheCategoriesList = ({categories, title, url}) => {
    console.log(categories, 'categories')

    return (
        <section className="category-list-filter__body">
            <h2>{ title }</h2>
            <ul className="category-list-filter__list">
                {categories.map((category) => {
                    return (
                        <li
                            id={category.title.replace(/ /g,'_')}
                            key={category.id}
                            role="presentation"
                        >
                            <div>
                                <span className="category-list-filter__item_name first-child">
                                    { category.title }
                                    <i>{ category.posts_count }</i>
                                </span>
                                <SubcategoryList
                                    categories={category.categories}
                                    url={url}
                                    category={category}
                                />
                            </div>
                        </li>
                    )
                })}
            </ul>
        </section>
    )
}

export default TheCategoriesList

const SubcategoryList = ({categories, url, category}) => {

    const sortCategories = categories.sort((a, b) => {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    });

    return (
        <ul className="category-list-filter__sublist">
            {sortCategories.map((subcategory) => {
                return (
                    subcategory.posts_count > 0 &&
                    <li
                        key={subcategory.id}
                        className='category-list-filter__sublist_item'
                    >
                        <a
                            href={`/${url}/${category.slug}/${subcategory.slug}`}
                            className='category-list-filter__sublist_item_name'
                            title={subcategory.title}
                        >
                            <span>{subcategory.title}</span> <i>{subcategory.posts_count}</i>
                        </a>

                    </li>
                )
            })}
        </ul>
    )
}